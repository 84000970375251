import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'gatsby';

import Image from '../Image';
import CTAButton from '../CTAButton/CTAButton';


const styles = theme =>
  createStyles({

  ctaWrap: {
      flex: '0 0 100%',
      maxWidth: '100%%',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '30px',

      [theme.breakpoints.up(767)]: {
        flex: '0 0 50%',
        maxWidth: '50%',
        alignItems: 'center'
      },
      [theme.breakpoints.up(960)]: {
        flex: '0 0 33.33%',
        maxWidth: '33.33%',
        padding: '30px 15px',
      },
      [theme.breakpoints.up(1010)]: {
        padding: '30px',
      },
      [theme.breakpoints.up(1270)]: {
        padding: '20px 67px',
      },
  },

  contentWrap: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: '450px'
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    marginBottom: 20,
    [theme.breakpoints.up(400)]: {
      fontSize: 32,
    },
    [theme.breakpoints.up(960)]: {
      textAlign: 'center',
      fontSize: 38,
      letterSpacing: 1,
    },
  },

  text: {
    marginBottom: 10,
    fontSize: 20,
    lineHeight: '24px',
    [theme.breakpoints.up(960)]: {
      textAlign: 'center',
    },
  },

  articleTitleLink: {
    textDecoration: 'none',

  },

  buttonWrap: {
    justifyContent: 'center',
  },

  iconWrap: {
    marginBottom: 30,
    minHeight: 62,

  },
  textWrap: {
    marginBottom: 25,
    
  },
  icon: {
    width: 60,
    height: 'auto',
    margin: '0 auto'
  }

  });

const CtaCard2 = ({
    classes,
    image: imageRelativePath,
    title,
    ctaHref,
    text,
    theme,
    ctaState = null,
    externalLink = false,
    ctaText,
    inverted

}) => (


  <Grid
    container
    direction="column"
    className={classes.ctaWrap}
  >

    {imageRelativePath && (
        <div className={classes.iconWrap}>
          <Image relativePath={imageRelativePath} className={classes.icon} />
        </div>
    )}

    <Grid
        container
        direction="column"
        className={classes.contentWrap}
    >

        <Link className={classes.articleTitleLink} to={ctaHref}>
            <Typography variant="h4" component="h4" className={classes.title}>
                {title}
            </Typography>
        </Link>

        <Grid
          item
          className={classes.textWrap}
        >
          <Typography
              variant="body1"
              className={classes.text}
            >
            {text}
         </Typography>
         </Grid>


        {ctaHref && ctaText && (
          <Grid container item className={classes.buttonWrap}>
            <CTAButton
              ctaHref={ctaHref}
              ctaText={ctaText}
              destinationState={ctaState}
              // btnStyles={{
              //   backgroundColor: theme.palette.primary.main,
              // }}
              inverted={inverted} //
              external={externalLink}
            />
          </Grid>
        )}
    </Grid>

  </Grid>

  );

export default withStyles(styles, { withTheme: true })(CtaCard2);