import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { pxToRem } from '../theme';

const styles = theme =>
  createStyles({
    statementWrap: {
      backgroundColor: '#E9E9E9',
      textAlign: 'center',
      padding: '50px',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        textAlign: 'left',
      },
      [theme.breakpoints.up(1500)]: {
          padding: '20px 100px 20px 80px',
      },
      [theme.breakpoints.up(1700)]: {
        padding: '20px 300px 20px 80px',
      },
    },

    statementTitle: {
      marginBottom: 40,
      fontWeight: 600,
      fontSize: pxToRem(32),
      letterSpacing: '-0.5px',
      [theme.breakpoints.up(767)]: {
        marginBottom: 30,
      },
    },
    
    statementCopy: {
      fontSize: pxToRem(20),
      letterSpacing: '0.5px',
      lineHeight: pxToRem(24),
      [theme.breakpoints.up(767)]: {
        fontSize: pxToRem(24),
        lineHeight: pxToRem(36),
        letterSpacing: '0.7px',
      },
    }
  });

const StatementIntro = ({
  classes,
  title,
  statementCopy,
}) => (
  <Grid
    container
    className={classes.statementWrap}
    direction="column"
  >
      {title && <Typography
          variant="h3"
          color="primary"
          className={classes.statementTitle}
        >
          {title}
        </Typography>
      }
      {statementCopy && (
        <Typography variant="body1" color="primary"
          className={classes.statementCopy}
        >
          {statementCopy}
        </Typography>
      )}
  </Grid>
);

export default withStyles(styles, { withTheme: true })(StatementIntro);