import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

//Images
import { ConstructionPageImages } from '../imageURLs';

import SEO from '../components/SEO';
import { withQuoteWizard } from '../components/withQuoteWizard';
import SectionTitle from '../components/SectionTitle';
import StatementIntro from '../components/StatementIntro';
import CtaCard2 from '../components/CtaCard2/CtaCard2';
import CenterContentNarrow from '../components/CenterContentNarrow';
import Image from '../components/Image';
import HeroPlayer from '../components/HeroPlayer/HeroPlayer';

const videoUrl="https://www.youtube.com/watch?v=bLQmG9qkwi8";

const cardItems = [
  {
    imagePath: ConstructionPageImages.icon1,
    title: "Our People",
    text: "We are driven by a team of experts who specialise in creating premium sports field constructions.",
    ctaHref: "/our-people",
    ctaText: "Learn more",
  },
  {
    imagePath: ConstructionPageImages.icon2,
    title: "Our Projects",
    text: "We are renowned for quality and offer customised solutions for a diverse range of projects.",
    ctaHref: "/our-projects",
    ctaText: "Learn more",
  },
  {
    imagePath: ConstructionPageImages.icon3,
    title: "Our Services",
    text: "We offer a suite of services across sports field construction, renovation and maintenance.",
    ctaHref: "/our-services",
    ctaText: "Learn more",
  },
]

const styles = theme =>
  createStyles({
    titleWrap: {
      marginTop: 40,
      marginBottom: 10,
      [theme.breakpoints.up(960)]: {
        marginTop: '60px',
        marginBottom: '50px'
      },

    },

    img: {
      width: '100%',
      height: '458px',
      objectFit: 'cover',
      overflow: 'hidden',
      [theme.breakpoints.up(1280)]: {
        height: '100%',
      },
    },

    sectionWrap: {
      [theme.breakpoints.up(1280)]: {
        flexDirection: 'row'
      },
    },
    
    imageWrap: {
      flex: '0 0 100%',
      maxWidth: '100%',
      maxHeight: '506px',
      overflow: 'hidden',
      [theme.breakpoints.up(1280)]: {
        flex: '0 0 50%',
        maxWidth: '50%',
        maxHeight: '460px'
      },
    },

    textWrap: {
      flex:'0 0 100%',
      maxWidth: '100%',
      [theme.breakpoints.up(1280)]: {
        flex: '0 0 50%',
        maxWidth: '50%',
      },
    },

    cardsWrap: {
      justifyContent: 'space-between',
      margin: '40px 0',
      padding: '0 15px',
      [theme.breakpoints.up(767)]: {
        justifyContent: 'center'
      },
    },
  });

const ConstructionPage = ({classes,children}) => (

<React.Fragment>
    <SEO
      title="Construction - Twin View Turf - Producer of Australia’s Best Lawns"
      keywords={[
        `Turf care guides`,
        `care guides`,
        `Turf`,
        `Lawns`,
        `Best Lawns`,
        `Australia’s Best Lawns`,
        `lawn producer`,
      ]}
    />
      {/* HERO VIDEO SECTION */}
      <HeroPlayer url={videoUrl} />

      <Grid
        item
        className={classes.titleWrap}
      >
        <SectionTitle
          title="The sports field construction specialists."
        ></SectionTitle>
      </Grid>

      <Grid
        container
        direction="column"
        className={classes.sectionWrap}
      >
        <Grid
          item
          className={classes.imageWrap}
        >
          <Image
            relativePath={ConstructionPageImages.thumb}
            className={classes.img}
            imgStyle={{ width: '100%' }}
          />
        </Grid>

        <Grid
          item
          container
          className={classes.textWrap}
        >
          <StatementIntro
            title="An industry leader you can trust."
            statementCopy="Twin View Turf are Australia’s leading sports turf supplier and sports field construction company. We construct, renovate and maintain premium playing surfaces for local schools, sporting clubs and international stadiums. We produce Australia’s finest sports turf suitable for all sporting applications."
          ></StatementIntro>
        </Grid>

      </Grid>

    <CenterContentNarrow style={{ maxWidth: '1270px' }}>
      <Grid
        container
        className={classes.cardsWrap}
        >
          {cardItems.map((item) => (
            <CtaCard2
              key={item.title}      
              image={item.imagePath}
              title={item.title}
              text={item.text}
              ctaHref={item.ctaHref}
              ctaText={item.ctaText}
              inverted
          ></CtaCard2>
          ))}
      </Grid>
    </CenterContentNarrow>
  {children}
</React.Fragment>

)

export default withQuoteWizard(withStyles(styles, { withTheme: true })(ConstructionPage));