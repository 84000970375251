import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player/lazy';


const styles = theme =>
  createStyles({
    playerHero: {
      position: 'relative',
      minHeight: '506px',
      [theme.breakpoints.up(767)]: {
        minHeight: '660px',
      },
    },

    playerWrapper: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    },

    video: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: "translate(-50%,-50%)",
      [theme.breakpoints.up(1920)]: {
        width: '100vw !important',
        height: '57vw !important' 
      },
    }
  });

const HeroPlayer = ({classes,url}) => (
    <div className={classes.playerHero}>
      <div className={classes.playerWrapper}>
        <ReactPlayer url={url} width="1920px" height="1080px" muted loop controls playing className={classes.video}/>
      </div>
    </div>
);

  export default withStyles(styles, { withTheme: true })(HeroPlayer);